<!-- Contact Section -->
<section 
  class="page-section"
  mdbScrollspyElement="scrollspy" 
  id="contact">
    <div class="container">
      <!-- Contact Section Heading -->
      <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">
        Contacto!
      </h2>
      <!-- Icon Divider -->
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><fa-icon [icon]="faStar"></fa-icon></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- Contact Section Form -->
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7">
          <!-- REVISAR LA PARTE DE FORMULARIO -->
          <form 
            id="contactForm" 
            [formGroup]="contactForm"
            (ngSubmit)="onSubmit(contactForm.value)"
            action="https://formspree.io/f/mdoylgrv"
            method="POST">
            <!-- Name input -->
            <div class="form-floating mb-3">
              <input 
                class="form-control" 
                id="name"
                name="name"
                placeholder="Nombre"  
                type="text" 
                formControlName="name" 
              />
              <label for="name">Nombre</label>
              <div 
                class="invalid-feedback d-block" 
                *ngIf="!contactForm.controls['name'].valid && contactForm.controls['name'].touched"
              >Debes indicar un email.</div>
            </div>
            <!-- Email address input -->
            <div class="form-floating mb-3">
              <input 
                type="email"
                name="_replyto" 
                class="form-control" 
                id="email" 
                placeholder="Email"
                formControlName="email"
              />
              <label for="email">Email</label>
              <div 
                class="invalid-feedback d-block"
                *ngIf="!contactForm.controls['email'].valid && contactForm.controls['email'].touched"
              >Debes indicar un email válido.</div>
            </div>
            <!-- Phone number input -->
            <div class="form-floating mb-3">
              <input 
                type="text" 
                class="form-control" 
                id="phone" 
                placeholder="Teléfono" 
                formControlName="phone"
              />
              <label for="phone">Teléfono</label>
              <div 
                class="invalid-feedback d-block"
                *ngIf="!contactForm.controls['phone'].valid && contactForm.controls['phone'].touched"
              >Debes indicar un número de teléfono.</div>
            </div>
            <!-- Message input -->
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                id="message"
                type="text"
                placeholder="Mensaje"
                style="height: 10rem"
                formControlName="message"></textarea>
            <label for="message">Mensaje</label>
              <div 
                class="invalid-feedback d-block"
                *ngIf="!contactForm.controls['message'].valid && contactForm.controls['message'].touched"
              >Debes escribir un mensaje.</div>
            </div>
            <!-- Submit success message -->
            <!-- This is what users will see when the form
            has successfully submitted -->
            <div 
              class="d-none" 
              id="submitSuccessMessage">
              <div class="text-center mb-3">
                <div class="fw-bolder">Mensaje enviado!</div>
              </div>
            </div>
            <!-- Submit error message -->
            <!-- This is what users will see when there is an error submitting the form -->
            <div class="d-none" id="submitErrorMessage">
              <div class="text-center text-danger mb-3">
                Error al enviar mensaje!
              </div>
            </div>
            <!-- Submit button -->
            <button 
              class="btn btn-primary btn-xl" 
              id="submitButton" 
              type="submit"
              value="Submit"
              [disabled]="!contactForm.valid">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  </section>
  