<!-- Portfolio Section -->
<section class="page-section portfolio" id="portfolio" mdbScrollspyElement="scrollspy">
  <div class="container">
    <!-- Portfolio Section Heading -->
    <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">
      Portfolio
    </h2>
    <!-- Icon Divider -->
    <div class="divider-custom">
      <div class="divider-custom-line"></div>
      <div class="divider-custom-icon"><fa-icon [icon]="faStar"></fa-icon></div>
      <div class="divider-custom-line"></div>
    </div>
    <!-- Portfolio Grid Items-->
    <div class="row justify-content-center">
      <!-- Portfolio Item 1-->
      <div *ngFor="let project of projects" class="col-md-6 col-lg-4 mb-5">
        <div class="portfolio-item mx-auto" (click)="openSM(contenido)">
            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                <div class="portfolio-item-caption-content text-center text-white"><fa-icon [icon]="faPlus" size="3x"></fa-icon></div>
            </div>
            <img class="img-fluid" [src]="project.projectImage" alt="..." />
        </div>
          
        <!-- Modal -->
        <ng-template #contenido let-modal class="modal fade">
          <div class="modal-header border-0">
            <button class="btn-close" type="button" (click)="modal.close()"></button>
          </div>
          <div class="modal-body text-center pb-5">
              <div class="row justify-content-center">
                <div class="col-lg-8">
                    <!-- Portfolio Modal - Title-->
                    <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">{{project.projectTitle}}</h2>
                    <!-- Icon Divider-->
                    <div class="divider-custom">
                        <div class="divider-custom-line"></div>
                        <div class="divider-custom-icon"><fa-icon [icon]="faStar"></fa-icon></div>
                        <div class="divider-custom-line"></div>
                    </div>
                    <!-- Portfolio Modal - Image-->
                    <img class="img-fluid rounded mb-5" [src]="project.projectImage" alt="..." />
                    <!-- Portfolio Modal - Text-->
                    <p class="mb-4">{{project.projectDescription}}</p>
                    <p class="mb-4"><a href={{project.projectLink}} target="_blank">Click aquí</a> para verlo en vivo.</p>
                    <p class="mb-4"><a href={{project.projectGithub}} target="_blank">Click aquí</a> para verlo en Github.</p>
                    <button class="btn btn-primary" href="#!" (click)="modal.close()">
                      <fa-icon [icon]="faTimes"></fa-icon>
                        Cerrar
                    </button>
                </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
  



  