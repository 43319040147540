import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';


import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  contactForm: FormGroup;
  post: any;
  name: string = '';
  email: string = '';
  phone: string = '';
  message: string = '';
  

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  phonePattern = "[0-9]{9}";


  // Font awesome icons
  faStar = faStar;
  
  constructor( 
    private fb: FormBuilder,
    private http: HttpClient
    ) {
    
    this.contactForm = fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern(this.phonePattern)])],
      'message': [null, Validators.required],
      'validate': ''
    })
  }

  ngOnInit(): void {
    
  }

  onSubmit(contactMessage): void {
    this.name = contactMessage.name;
    this.email = contactMessage.email;
    this.phone = contactMessage.phone;
    this.message = contactMessage.message;
    document.querySelector("#submitButton").classList.add("d-none");
    document.querySelector("#submitSuccessMessage").classList.remove("d-none");

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/mdoylgrv',
        { name: this.name, replyto: this.email, phone: this.phone, message: this.message },
        { 'headers': headers }).subscribe(
          response => {
            console.log(response);
          }
        );
  }


}
