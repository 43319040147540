<!-- About Section -->
<section 
  class="page-section bg-primary text-white mb-0" 
  mdbScrollspyElement="scrollspy" 
  id="about">
    <div class="container">
      <h2 class="page-section-heading text-center text-uppercase text-white">Sobre mí</h2>
      <!-- Icon Divider -->
      <div class="divider-custom divider-light">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><fa-icon [icon]="faStar"></fa-icon></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- About Section Content -->
      <div class="row">
        <div class="col-lg-8 ms-auto">
          <p class="lead">Junior Front-End Developer con experiencia en:  </p>
          <ul>
            <li>HTML 5</li>
            <li>CSS 3 / SASS</li>
            <li>BOOTSTRAP</li>
            <li>JAVASCRIPT ES6</li>
            <li>TYPESCRIPT</li>
            <li>ANGULAR</li>
            <li>GIT / GITHUB</li>
          </ul>
        </div>
      </div>
      <!-- About Section Button -->
      <div class="text-center mt-4">
        <a href="#contact" class="btn btn-xl btn-outline-light">
          <i class="me-2">Contacto!</i>
        </a>
      </div>
    </div>
  </section>
  