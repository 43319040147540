import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faStar, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PROJECTS } from '../project-list';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  projects = PROJECTS;

  // Font awesome icons
  faStar = faStar;
  faPlus = faPlus;
  faTimes = faTimes;

  constructor(private modal:NgbModal) { }

  ngOnInit(): void {
  }

  openSM(contenido) {
    this.modal.open(contenido, { size: 'xl'});
  }

}
