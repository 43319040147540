<!-- Footer -->
<footer class="footer text-center">
    <div class="container">
      <div class="row">
        <!-- Footer Location -->
        <div class="col-lg-4 mb-5 mb-lg-0">
          <h4 class="text-uppercase mb-4">Ubicación</h4>
          <p class="lead mb-0">
            Ciutadella de Menorca
            <br>
            Islas Baleares, España
          </p>
        </div>
        <!-- Footer Scoial Icons -->
        <div class="col-lg-4 mb-5 mb-lg-0">
                    
          <h4 class="text-uppercase mb-4">Redes Sociales</h4>
          <!--
          <a href="" class="btn btn-outline-light btn-social mx-1"><fa-icon [icon]="faFacebook"></fa-icon></a>
          <a href="" class="btn btn-outline-light btn-social mx-1"><fa-icon [icon]="faTwitter"></fa-icon></a>
          -->
          <a href="https://github.com/CharlieCastleWeb" class="btn-xl btn-outline-light btn-social mx-1" target="_blank"><fa-icon [icon]="faGithub"></fa-icon></a>
          <a href="https://www.linkedin.com/in/carlos-castillo-martinez-68405218/" class="btn-xl btn-outline-light btn-social mx-1" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
        </div>
        <!-- Footer About Text -->
        <div class="col-lg-4">
          <h4 class="text-uppercase mb-4">Situación actual</h4>
          <p class="lead mb-0">
            Freelance, interesado en nuevas oportunidades
          </p>
        </div>
      </div>
    </div>
  </footer>
  <!-- Copyright Section -->
  <div class="copyright py-4 text-center text-white">
    <div class="container"><small>Copyright &copy; Carlos Castillo 2021</small></div>
  </div>
  