<!-- Masthead -->
<header 
  class="masthead bg-primary text-white text-center" 
  mdbScrollspyElement="scrollspy"
  id="masthead">
    <div class="container d-flex align-items-center flex-column">
      <!-- Masthead Avatar Image -->
      <img src="../../assets/img/avataaars.svg" alt="..." class="masthead-avatar mb-5">
      <!-- Masthead Heading -->
      <h1 class="masthead-heading text-uppercase mb-0">Carlos Castillo</h1>
      <!-- Icon Divider -->
      <div class="divider-custom divider-light">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><fa-icon [icon]="faStar"></fa-icon></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- Masthead Subheading -->
      <p class="masthead-subheading font-weight-light mb-0">Angular Developer</p>
    </div>
</header>
  