<nav
  class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top"
  id="mainNav"
  [class.navbar-shrink]="scrollVariable">
  <div class="container">
    <a href="#masthead" class="navbar-brand">CARLOS CASTILLO</a>
    <button
      class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded"
      type="button"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="responsiveNavItems()">
        Menu <fa-icon [icon]="faBars"></fa-icon>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item mx-0 mx-lg-1">
          <!-- Al siguiente enlace debería añadirle 
                [routerLink]="['portfolio']" 
              para que funcionaran los dynamic tags -->
          <a 
            class="nav-link py-3 px-0 px-lg-3 rounded" 
            href="#portfolio"
            >
              Portfolio</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <!-- Al siguiente enlace debería añadirle 
                [routerLink]="['about']" 
              para que funcionaran los dynamic tags -->
          <a 
            class="nav-link py-3 px-0 px-lg-3 rounded"
            href="#about"
            >
              SOBRE MI</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <!-- Al siguiente enlace debería añadirle 
                [routerLink]="['contact']" 
              para que funcionaran los dynamic tags -->
          <a 
            class="nav-link py-3 px-0 px-lg-3 rounded" 
            href="#contact">Contacto</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

