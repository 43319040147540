import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';



const routes: Routes = [
  {
    path: 'portfolio',
    component: PortfolioComponent,
    pathMatch: 'full',
    data: {
      title: 'Portfolio Projects',
      description: 'Web Developement Projects',
      ogTitle: 'Web Developement Projects'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'About Me',
      description: 'Information About Me',
      ogTitle: 'Information About Me'
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Contact Form',
      description: 'Form To Contact Me',
      ogTitle: 'Form To Contact Me'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
