import { Project } from './project';

export const PROJECTS: Project[] = [
    {
        projectImage: "assets/img/portfolio/TravelBlog.png",
        projectTitle: "Travel Blog Main Page",
        projectDescription: "Página inicial para blog de viajes. Desarrollado en HTML y CSS usando Flexbox y Media Queries para conseguir un diseño responsive. Tipografía con Google Fonts.",
        projectLink: "https://charliecastleweb.github.io/M3-FlexLayout-Nivell2/",
        projectGithub: "https://github.com/CharlieCastleWeb/M3-FlexLayout-Nivell2"
      },
      {
        projectImage: "assets/img/portfolio/Leaflet1.png",
        projectTitle: "Mapa con coordenadas",
        projectDescription: "Mapa interactivo que indica las coordenadas del punto marcado. Desarrollado en HTML, CSS y Javascript usando la librería Leaflet (leafletjs.com).",
        projectLink: "https://charliecastleweb.github.io/M10-LEAFLET/",
        projectGithub: "https://github.com/CharlieCastleWeb/M10-LEAFLET"
      },
      {
        projectImage: "assets/img/portfolio/Portfolio.png",
        projectTitle: "Portfolio",
        projectDescription: "Web Portfolio desarrollado en HTML, CSS (Flexbox, Grid) y Javascript.",
        projectLink: "https://charliecastleweb.github.io/scrimba-portfolio-project/",
        projectGithub: "https://github.com/CharlieCastleWeb/scrimba-portfolio-project"
      },
      {
        projectImage: "assets/img/portfolio/ShoeShop.png",
        projectTitle: "Ecommerce Sandalias",
        projectDescription: "Ecommerce de sandalias desarrollado con Bootstrap, HTML y SCSS, siguiendo una metodología mobile first e incluyendo una página modal y un slider de imágenes.",
        projectLink: "https://charliecastleweb.github.io/M6-Bootstrap/",
        projectGithub: "https://github.com/CharlieCastleWeb/M6-Bootstrap"
      },
      {
        projectImage: "assets/img/portfolio/Rockets.png",
        projectTitle: "Rockets Game",
        projectDescription: "Juego simple de cohetes desarrollado con Bootstrap, HTML y Typescript, siguiendo metodología de modelo vista controlador y orientación a objetos.",
        projectLink: "https://charliecastleweb.github.io/M7-1-Exercici-coets-Nivell2/",
        projectGithub: "https://github.com/CharlieCastleWeb/M7-1-Exercici-coets-Nivell2"
      },
      {
        projectImage: "assets/img/portfolio/Vehicles.png",
        projectTitle: "Submarine",
        projectDescription: "Página formulario desarrollada con Bootstrap, HTML y Typescript/Javascript. Incluye validación de formulario con Regular Expressions",
        projectLink: "https://charliecastleweb.github.io/M7-1-Exercici-vehicles/",
        projectGithub: "https://github.com/CharlieCastleWeb/M7-1-Exercici-vehicles/tree/gh-pages"
      }
]