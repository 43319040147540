import { Component, OnInit, HostListener } from '@angular/core';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Bootstrap } from 'node_modules/bootstrap/dist/js/bootstrap.js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  // Font awesome bar icon
  faBars = faBars;

  scrollVariable = false;

  @HostListener("document:scroll")
  // Navbar shrink function
  navbarShrink() {
    const navbarCollapsible = document.body.querySelector('#mainNav');
    if (!navbarCollapsible) {
      return;
    }
    if (window.scrollY === 0) {
      this.scrollVariable = false;
    } else {
      this.scrollVariable = true;
    }
  };


  // Collapse responsive navbar when toggler is visible
  
  responsiveNavItems() {
      document.querySelector("#navbarResponsive").classList.toggle("show");
  }
  
}
